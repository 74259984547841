import { api } from "../utils/request";

async function getExpenses(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/expenses/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function addExpense(expense) {
	const res = await api({
		method: "post",
		url: "/api/expenses",
		data: expense,
	});

	return res;
}

async function editExpense(expense) {
	const res = await api({
		method: "put",
		url: `/api/expenses/${expense._id}`,
		data: expense,
	});

	return res;
}

async function deleteExpense(id) {
	const res = await api({
		method: "delete",
		url: `/api/expenses/${id}`,
	});

	return res;
}

export { getExpenses, addExpense, editExpense, deleteExpense };
