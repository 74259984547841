<style>
.card {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	padding: 10px;
}

.totalBlock {
	border: 1px solid #777;
	border-radius: 3px;
	padding: 10px;
	width: 215px;
}

.totalValues {
	text-align: left;
	margin-top: 10px;
}

.totalWithPercent {
	display: flex;
	gap: 10px;
	justify-content: space-between;
	margin-top: 10px;
}
</style>

<template>
	<v-skeleton-loader
		v-if="!totals"
		type="image"
		class="mx-auto"
		style="width: 100%; height: 125px; max-width: 1175px"
	/>
	<v-card v-else class="card mx-auto">
		<div class="totalBlock">
			Margem
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<PercentageChip :stats="totals" stat="margin" :absoluteFirst="true" :currency="true" :onClick="() => {}" />
			<br />
			<PercentageChip :stats="totals" stat="margin" :onClick="() => {}" />
		</div>
		<div class="totalBlock">
			Receita
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.billing, true) || "0 €" }}
				<div class="totalWithPercent">
					{{ formatNumber(totals.billingBanks, true) || "0 €" }}
					<v-chip small>
						{{ formatNumber((totals.billingBanks * 100) / totals.billing) || 0
						}}<v-icon x-small right>mdi-percent</v-icon>
					</v-chip>
				</div>
			</div>
		</div>
		<div class="totalBlock">
			Despesas
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.billingCommissions + totals.billingCommercialDirectorCommissions, true) || "0 €" }}
			</div>
			<div class="totalValues">
				{{ formatNumber(totals.value, true) || "0 €" }}
			</div>
		</div>
	</v-card>
</template>

<script>
import Vue from "vue";

import PercentageChip from "../components/dashboard/PercentageChip.vue";

import { formatNumber } from "../utils/utils";

export default Vue.extend({
	name: "ReservationTotals",
	props: ["pipeline", "totals"],
	components: { PercentageChip },
	methods: {
		formatNumber,
	},
});
</script>
