<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleAddExpense">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 400px">
					<v-select
						outlined
						v-model="office"
						:items="offices"
						item-text="name"
						item-value="key"
						label="Agência"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-text-field
						outlined
						v-model="name"
						label="Nome"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field outlined v-model="date" label="Data" v-bind="attrs" v-on="on" readonly hide-details />
						</template>
						<v-date-picker v-model="date" color="primary" />
					</v-menu>
					<v-checkbox v-model="repeatsMonthly" label="Repete Mensalmente" />
					<v-combobox
						outlined
						v-model="category"
						item-text="name"
						item-value="key"
						label="Categoria"
						:items="config.expenseCategories"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-combobox
						outlined
						v-model="subCategory"
						item-text="name"
						item-value="key"
						label="Sub-categoria"
						:items="config.expenseSubCategories"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-text-field
						outlined
						append-icon="mdi-currency-eur"
						v-model="value"
						label="Valor"
						type="value"
						:rules="[required]"
						hint="Obrigatório"
						persistent-hint
					/>
					<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="paymentDate"
								label="Data de Pagamento"
								v-bind="attrs"
								v-on="on"
								readonly
							/>
						</template>
						<v-date-picker v-model="paymentDate" color="primary" />
					</v-menu>
					<v-textarea outlined v-model="observations" label="Observações" height="145px" hide-details />
				</v-col>
			</v-row>
			<v-btn v-if="!expense" @click="handleAddExpense">Adicionar</v-btn>
			<v-btn v-if="expense" @click="handleEditExpense">Atualizar</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";

import { mapState, mapMutations } from "vuex";

import { addExpense, editExpense } from "../api/expenses";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "ExpenseForm",
	props: ["expense", "onClose"],
	created() {
		this.office = this.selectedOffices[0];

		if (this.expense) this.setExpense(this.expense);
	},
	watch: {
		selectedOffices: function () {
			this.office = this.selectedOffices[0];
		},
		expense: function (newVal) {
			if (newVal) {
				this.setExpense(newVal);
			} else {
				this.office = this.selectedOffices[0];
				this.name = "";
				this.category = null;
				this.subCategory = null;
				this.date = formatDate(dayjs(), "YYYY-MM-DD");
				this.repeatsMonthly = false;
				this.value = "";
				this.paymentDate = null;
				this.observations = "";
			}
		},
	},
	computed: mapState(["company", "offices", "selectedOffices", "config", "features"]),
	data() {
		return {
			loading: false,

			required: value => !!value || "Obrigatório",

			office: null,
			name: "",
			category: null,
			subCategory: null,
			date: formatDate(dayjs(), "YYYY-MM-DD"),
			repeatsMonthly: false,
			value: "",
			paymentDate: null,
			observations: "",
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setExpense(newVal) {
			this.office = newVal.office;
			this.name = newVal.name;
			this.category = newVal.category;
			this.subCategory = newVal.subCategory;
			this.date = formatDate(newVal.date, "YYYY-MM-DD");
			this.repeatsMonthly = newVal.repeatsMonthly;
			this.value = newVal.value;
			this.paymentDate = newVal.paymentDate && formatDate(newVal.paymentDate, "YYYY-MM-DD");
			this.observations = newVal.observations;
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleAddExpense() {
			this.loading = true;

			const { office, name, category, subCategory, date, repeatsMonthly, value, paymentDate, observations } = this;

			if (!this.isFormValid()) return;

			const response = await addExpense({
				office,
				name,
				category: category.key || category,
				subCategory: subCategory.key || subCategory,
				date,
				repeatsMonthly,
				value,
				paymentDate,
				observations,
			});

			if (response.status === 201) {
				if (response.data) {
					window.location.reload();
				} else {
					this.onClose();
				}
			}

			this.loading = false;
		},
		async handleEditExpense() {
			this.loading = true;

			const { office, name, category, subCategory, date, repeatsMonthly, value, paymentDate, observations } = this;

			if (!this.isFormValid()) return;

			const response = await editExpense({
				_id: this.expense._id,
				office,
				name,
				category: category.key || category,
				subCategory: subCategory.key || subCategory,
				date,
				repeatsMonthly,
				value,
				paymentDate,
				observations,
			});

			if (response.status === 200) {
				if (response.data) {
					window.location.reload();
				} else {
					this.onClose();
				}
			}

			this.loading = false;
		},
	},
});
</script>
